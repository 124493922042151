// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bookshelf-index-jsx": () => import("./../../../src/pages/bookshelf/index.jsx" /* webpackChunkName: "component---src-pages-bookshelf-index-jsx" */),
  "component---src-pages-code-games-gobblet-jsx": () => import("./../../../src/pages/code/games/gobblet.jsx" /* webpackChunkName: "component---src-pages-code-games-gobblet-jsx" */),
  "component---src-pages-code-games-index-jsx": () => import("./../../../src/pages/code/games/index.jsx" /* webpackChunkName: "component---src-pages-code-games-index-jsx" */),
  "component---src-pages-code-index-jsx": () => import("./../../../src/pages/code/index.jsx" /* webpackChunkName: "component---src-pages-code-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-music-index-jsx": () => import("./../../../src/pages/music/index.jsx" /* webpackChunkName: "component---src-pages-music-index-jsx" */),
  "component---src-pages-words-index-jsx": () => import("./../../../src/pages/words/index.jsx" /* webpackChunkName: "component---src-pages-words-index-jsx" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */),
  "component---src-pages-yolkgirl-jsx": () => import("./../../../src/pages/yolkgirl.jsx" /* webpackChunkName: "component---src-pages-yolkgirl-jsx" */),
  "component---src-templates-music-jsx": () => import("./../../../src/templates/music.jsx" /* webpackChunkName: "component---src-templates-music-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-words-jsx": () => import("./../../../src/templates/words.jsx" /* webpackChunkName: "component---src-templates-words-jsx" */)
}

